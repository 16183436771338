import { Center, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";
import { Routes } from "constants/routes";
import useColors from "hooks/useColors";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { BiHeart, BiSolidDashboard, BiCategory, BiStore } from "react-icons/bi";
import { FaStore } from "react-icons/fa";
// import { RiFeedbackLine } from 'react-icons/ri';
import { RiSecurePaymentFill } from "react-icons/ri";
import WhiteLogo from "assets/svgs/whiteLogo.svg";

const Sidebar = () => {
  const [activeBox, setActiveBox] = useState<Routes>(Routes.DASHBOARD);
  const { primaryColor, secondaryColor } = useColors();
  const [isLargerThan768] = useMediaQuery("(min-width: 1140px)");

  const links = [
    // {
    //   to: Routes.DASHBOARD_PRODUCTS,
    //   name: "Product Discovery",
    //   Icon: BiCategory,
    // },
    // { to: Routes.DASHBOARD_MARKET, name: "Market Research", Icon: BiSearch },
    // {
    //   to: Routes.DASHBOARD_TRACKING,
    //   name: "Competitors Tracking",
    //   Icon: BiLocationPlus,
    // },
    { to: Routes.DASHBOARD, name: "Dashboard", Icon: BiSolidDashboard },
    { to: Routes.DASHBOARD_PRODUCT_DISCOVERY, name: "Product Discovery", Icon: BiCategory, isBeta: true },
    { to: Routes.DASHBOARD_STORE_DISCOVERY, name: "Store Discovery", Icon: BiStore, isBeta: true },
    { to: Routes.PRODUCT_COLLECTION, name: "My Collections", Icon: BiHeart, isBeta: false },
    { to: Routes.DASHBOARD_BILLING, name: "Billing Plans", Icon: FaStore },
    {
      to: Routes.DASHBOARD_INVOICES,
      name: "Payment History",
      Icon: RiSecurePaymentFill,
    },
  ];

  const activate = (to: Routes) => {
    setActiveBox(to);
    return {};
  };

  return (
    <>
      <Flex
        flexDir="column"
        w="290px"
        h="100%"
        minH="100vh"
        gap="35px"
        bg="#0A1931"
        paddingTop="25px"
        paddingLeft="15px"
        paddingRight="15px"
        zIndex={150}
        display={isLargerThan768 ? "flex" : "none"}
      >
        <Flex w="100%">
          <Center justifyContent="flex-start" w="100%">
            <NavLink to={Routes.HOME} ><Image src={WhiteLogo} alt="logo" /></NavLink>
          </Center>
        </Flex>
        <Flex w="100%" flexDir="column" gap="12px">
          {links.map(({ to, name, Icon, isBeta }) => (
            <NavLink
              to={to}
              key={to}
              style={({ isActive }) => (isActive ? activate(to) : {})}
            >
              <Flex
                h="45px"
                align="center"
                flexDir="row"
                gap="20px"
                bgColor={activeBox === to ? "white" : "transparent"}
                color={activeBox ? secondaryColor : "white"}
                w="100%"
                padding="10px"
                borderRadius="6px"
                transition="all 0.3s ease-in-out"
              >
                <Icon
                  color={activeBox === to ? primaryColor : "white"}
                  fontSize="20px"
                  style={{ transition: "all 0.3s ease-in-out" }}
                />
                <Text
                  w="max-content"
                  fontSize="16px"
                  textAlign="left"
                  color={activeBox === to ? primaryColor : "white"}
                  transition="all 0.3s ease-in-out"
                  display="flex"
                  justifyContent="space-between"
                  gap="10px"
                >
                  {name} {isBeta ? <span className="beta">beta</span> : <></>}
                </Text>
              </Flex>
            </NavLink>
          ))}
        </Flex>
      </Flex>
    </>
  );
};

export default Sidebar;
